:root {
    --color-darkblue: #1D2130;
    --color-darkpurple: #1E1A4E;
    --color-purple: #3A3A72;
    --color-lightpurple: #64749C;

    --color-black: #000;
    --color-grey: #767676;
    --color-white: #fff; 

    --fonte-titulo: "Jaro", sans-serif;
    --fonte-principal: "Mirza", serif;
}

* {
    text-decoration: none;
    list-style: none;
    color: black;
}

body {
    margin: 0;
    padding: 0;
}

/* Estilização Cabeçalho */ 
.container-header {
    font-family: var(--fonte-titulo);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-header h1 {
    margin-left: 15px;
    letter-spacing: 2px;
}

.container-header .navbar {
    display: flex;
}

.navbar ul, li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-right: 15px;
}

.active {
    text-decoration: underline;
    text-underline-offset: 8px; 
    text-decoration-thickness: 2px; 
}

/* Estilizando - Rodapé */
.container-footer {
    font-family: var(--fonte-principal);
    background-color: #000;
    width: 100%;
}

.logo {
    height: 80px;
    margin-top: 25px; 
    margin-left: 35px;
}

.content-footer {
    display: flex;
} 

.conteudo h4 {
    color: var(--color-white);
}

.url {
    margin-bottom: 30px;
    color: var(--color-grey);
    cursor: pointer;
}

.forms-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 

    margin-left: 50%;
    padding: 20px;
}

.forms-footer h2 {
    color: var(--color-white);
}

.form-container {
    display: flex;
    align-items: center;
}

.form-input {
    border: 1px solid var(--color-white);
    background-color: transparent;
    padding: 10px 40px;
    color: var(--color-grey);
}

.form-button {
    background-color: var(--color-white);
    color: var(--color-darkblue);
    border: none;
    padding: 11px 20px;
    cursor: pointer;
}





