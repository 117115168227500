:root {
    --color-darkblue: #1D2130;
    --color-darkpurple: #1E1A4E;
    --color-purple: #3A3A72;
    --color-lightpurple: #64749C;

    --color-black: #000;
    --color-grey: #767676;
    --color-white: #fff; 

    --fonte-titulo: "Jaro", sans-serif;
    --fonte-principal: "Mirza", serif;
}

/* Tags Globais */ 
p {
    font-size: 1.2rem;
}

.titulo {
    font-size: 2.5rem;
}

.subtitulo {
    color: var(--color-darkblue);
    margin-bottom: 20px;
}
.linha-subtitulo {
    display: inline-block;
    width: 5%;
    height: 2px;
    background-color: var(--color-darkblue);
    vertical-align: middle; 
    margin-right: 10px; 
}


/* */
.container-home {
    font-family: var(--fonte-principal);
}

.fundo {
    width: 100%;
    height: auto;
}

.conteudo-fundo {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.conteudo-fundo h1 {
    font-family: var(--fonte-titulo);
    color: var(--color-white);
    font-size: 3.5rem;
    letter-spacing: 5px;
}
.conteudo-fundo h4 {
    font-size: 1.5rem;
    color: var(--color-white)
}

.btn01{
    background-color: white;
    color: black;
    font-family: 'Jaro', sans-serif;
    border-radius: 5px;
    padding: 10px 25px;
    cursor: pointer;
} 

.introducao {
    margin-top: 30px;
    margin-bottom: 30px;
}

.introducao-fundo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-fundo {
    max-width: 20%;
    margin-right: 10%;
    margin-left: 5%;
    
}

.intro-conteudo {
    margin-left: 10%;
}

/* Conteúdo Principal - Carrinhos Arduino */
.arduino {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--color-darkblue);
}

.subtitulo02 {
    color: var(--color-white);
    margin-bottom: 20px;
}
.linha-subtitulo02 {
    display: inline-block;
    width: 5%;
    height: 2px;
    background-color: var(--color-white);
    vertical-align: middle; 
    margin-right: 10px; 
}

.arduino-fundo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.conteudo-arduino {
    max-width: 20%;
    margin-right: 10%;
    margin-left: 5%;
}

.arduino-img {
    max-width: 35%; /* Ajuste conforme necessário */
}


.arduino-fundo h1, h2{
    color: var(--color-white);
}

.arduino-fundo p {
    color: var(--color-white);
}

