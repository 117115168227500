:root {
    --color-darkblue: #1D2130;
    --color-darkpurple: #1E1A4E;
    --color-purple: #3A3A72;
    --color-lightpurple: #64749C;

    --color-black: #000;
    --color-grey: #767676;
    --color-white: #fff; 

    --fonte-titulo: "Jaro", sans-serif;
    --fonte-principal: "Mirza", serif;
}

.container-contato {
    font-family: var(--fonte-principal);
}

.fundo-contato {
    background-color: var(--color-darkblue);
    height: 50vh;
    width: 100%;
}
